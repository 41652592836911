import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import content from '../../config/content'
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Button, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faBolt, faBook, faPhone } from '@fortawesome/free-solid-svg-icons'
import { NavContext } from '../../context/navContext';
import { faTrello } from '@fortawesome/free-brands-svg-icons';
import config from '../../config'

function ElevationScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  return React.cloneElement(children, {
    elevation: trigger ? 1 : 0,
  });
}

export default function Header() {
  const classes = useStyles();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent) 
  const [openDrawer, setOpenDrawer] = useState(false)

  const drawer = (
    <SwipeableDrawer 
      disableBackdropTransition={!iOS}
      disableDiscovery={!iOS}
      open={openDrawer}
      onClose={()=>setOpenDrawer(false)}
      onOpen={()=>setOpenDrawer(true)}
      classes={{
        paper: classes.drawer
      }}>
      <div className={classes.toolbarMargin} />  
      <List disablePadding>
        <ListItem className={classes.drawerItem} onClick={()=>setOpenDrawer(false)} divider button component={Link} to="/" >
          <FontAwesomeIcon icon={faHome} className={classes.icon}/> 
          <ListItemText>Home</ListItemText>
        </ListItem>
        <ListItem className={classes.drawerItem} onClick={()=>setOpenDrawer(false)} divider button component={Link} to="/skills">
          <FontAwesomeIcon icon={faBolt} transform={{ rotate: 10 }} className={classes.icon} />
          <ListItemText>Skills</ListItemText>
        </ListItem>
        <ListItem className={classes.drawerItem} onClick={()=>setOpenDrawer(false)} divider button component={Link} to="/projects">
          <FontAwesomeIcon icon={faTrello} className={classes.icon}/>
          <ListItemText>Projects</ListItemText>
        </ListItem>
        <ListItem className={classes.drawerItem} onClick={()=>setOpenDrawer(false)} divider button component={Link} to="/contact">
          <FontAwesomeIcon icon={faPhone} flip="horizontal" className={classes.icon}/>
          <ListItemText>Contact</ListItemText>
        </ListItem>
        <ListItem className={classes.drawerItem} onClick={()=>setOpenDrawer(false)} divider button >
          <a href={config.resumeUrl} target='_blank' rel="noopener noreferrer" className={classes.drawerItemResume}>
            <FontAwesomeIcon icon={faBook} className={classes.icon}/>
            <ListItemText>Resume</ListItemText>
          </a>
        </ListItem>
      </List>
    </SwipeableDrawer>
  )

  return (
    <NavContext.Consumer>
      {
        context => (
          <>
            <ElevationScroll>
              <AppBar color="secondary" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                  <Button 
                    component={Link} 
                    to="/" 
                    disableRipple 
                    className={classes.logo} 
                    onClick={()=>context.changeNavValue(0)}> 
                    <Typography className={classes.title} >
                      {content.headerName}
                    </Typography>
                  </Button>
                  <Tabs 
                    className={classes.tabContainer} 
                    indicatorColor="secondary"
                    value={context.currentNav}
                    onChange={context.changeNavValue}
                    textColor="primary">
                    <Tab className={classes.tab} label="home" component={Link} to="/" disableRipple />
                    {/* <Tab className={classes.tab} label="about" component={Link} to="/about" disableRipple/> */}
                    <Tab className={classes.tab} label="skills" component={Link} to="/skills" disableRipple/>
                    <Tab className={classes.tab} label="projects" component={Link} to="/projects" disableRipple/>
                    <Tab className={classes.tab} label="contact" component={Link} to="/contact" disableRipple/>
                  </Tabs>
                  <Button 
                    variant="outlined" 
                    className={classes.btn} 
                    href={config.resumeUrl} 
                    target='_blank'>
                      Resume
                  </Button>
                  <IconButton disableFocusRipple disableRipple disableTouchRipple className={classes.menuBtn} onClick={()=>setOpenDrawer(!openDrawer)}>
                    <MenuIcon />
                  </IconButton>
                  {drawer}
                </Toolbar>
              </AppBar>
            </ElevationScroll>
            <div className={classes.toolbarMargin} />
          </>
        )
      }
    </NavContext.Consumer>
  );
}

const useStyles = makeStyles(theme => ({
  appBar: {
    height: 55, 
    justifyContent: 'center',
    zIndex: theme.zIndex.modal + 1
  },
  toolbar:{
    [theme.breakpoints.up('xs')]:{
      width: '80%',
      margin: '0px auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  toolbarMargin: {
    ...theme.mixins.toolbar
  },
  logo: {
    '&:hover': {
      background: 'transparent'
    }
  },
  title:{
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontFamily: 'Pacifico'
  },
  tabContainer:{
    marginLeft: 'auto',
    [theme.breakpoints.only('xs')]:{
      display: 'none'
    }
  },
  tab: {
    fontWeight: 300,
    fontSize: '1rem',
    textTransform: 'none',
    minWidth: 10,
    marginRight: '15px',
    '&:hover':{
      color: theme.palette.primary.main,
      transition: '0.2s'
    }
  },
  btn: {
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'Pacifico',
    textTransform: 'none',
    border: '3px solid #4A90E2',
    boxSizing: 'border-box',
    borderRadius: '25px',
    color: '#868B92',
    '&:hover':{
      background: '#4A90E2',
      color: '#fff',
      transition: '0.3s'
    },
    [theme.breakpoints.only('xs')]:{
      display: 'none'
    }
  },
  menuBtn:{
    paddingTop: 0,
    paddingBottom: 0,
    '&:hover':{
      background: 'transparent'
    },
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]:{
      display: 'none'
    }
  },
  drawer: {
    background: theme.palette.primary.main,
    minWidth: 200
  },
  drawerItem: {
    color: "white"
  },
  drawerItemResume:{
    textDecoration: 'none',
    color: "white",
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    minWidth: 20,
    marginRight: 10,
  }
}));