import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppSpinner from './AppSpinner'

export default function({isLoading=false, onClick, children, ...restProps}) {
  const classes = useStyles()
  return (
    <Button 
      variant="contained" 
      className={classes.btn} 
      onClick={onClick}
      disabled={isLoading}
      style={isLoading ? {background: '#C6C7CC'} : null}
      {...restProps}>
      {isLoading && <AppSpinner />}
      <Typography>
        {children}
      </Typography>
    </Button>
  );
}

const useStyles = makeStyles(theme => ({
  btn:{
    fontFamily: 'Raleway', 
    fontWeight: 600, 
    marginTop: 10, 
    border: 'none', 
    borderRadius: 0, 
    padding: '10px 20px',  
    background: "#323332", 
    color: 'white',
    textTransform: 'none',
    '&:hover':{
      background: '#323332',
      transition: '0.2s'
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: '25ch',
      borderRadius: 0
    },
  },
}));
