import React, { useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Popover from '@material-ui/core/Popover';
import Grow from '@material-ui/core/Grow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import Badge from '@material-ui/core/Badge';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import content from '../../config/content' 

function ProjectCard(props){
  const classes = useStyles()
  const projectImageUrl = require(`../../assets/${props.projectImage}`)
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={projectImageUrl}
          title={props.projectName}
        />
        <CardContent>
          <Typography gutterBottom style={{textAlign: 'center', fontWeight: 600, color: '#323334', fontSize: '1.2rem'}}>
          {props.projectName}
          </Typography>
          {/* <Typography variant="body2" color="textSecondary" component="p" > */}
          <div style={{textAlign: 'center', minHeight: 75}}>
            {
              props.tags.map(tag => <CardItem>{tag}</CardItem>)
            }
          </div>
          {/* </Typography> */}
        </CardContent>
      </CardActionArea>
      <CardActions style={{background: '#DAEDFB', flexDirection: 'row-reverse'}}>
        {
          props.liveUrl ?
          <a href={props.liveUrl} target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon 
              icon={faPlayCircle} 
              className={classes.icon1} 
            />
          </a> :
          <FontAwesomeIcon 
            icon={faPlayCircle} 
            className={`${classes.icon1} ${classes.iconDisabled}`} 
          />
        }
        {
          props.githubUrl ?
          <a href={props.githubUrl} target='_blank' rel="noopener noreferrer">
            <FontAwesomeIcon 
              icon={faGithub} 
              className={classes.icon1} 
            />
          </a> :
          <FontAwesomeIcon 
            icon={faGithub} 
            className={`${classes.icon1} ${classes.iconDisabled}`} 
          />
        }
      </CardActions>
    </Card>
  )
}

function CardItem({children}){
  const classes = useStyles()
  return (
    // <span> 
      <Typography className={classes.cardItem}>
        {children}
      </Typography>
    // </span>
  )
}

function Projects(props) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [items, setItems] = React.useState(content.projects)
  const [filter, setFilter] = React.useState('All')

  const filters = [
    {
      name: 'All',
      count: 7
    },
    {
      name: 'React',
      count: 4
    },
    {
      name: 'React Native',
      count: 1
    },
    {
      name: 'Node/Express',
      count: 1
    },
    {
      name: 'HTML5/CSS3/JS',
      count: 2
    }
  ]

  useEffect(()=>{
    if(content && content.projects.length > 0){
      if(filter === 'All'){
        // if(items.length !== content.projects.length){
          setItems(content.projects)
          return 
        // }
      }
      else{
        const filterValue = filter.split('/')
        const filteredItems = content.projects.filter(project => {
          return project.tags.includes(filterValue[0]) || project.tags.includes(filterValue[1]) || project.tags.includes(filterValue[2])
        })
        setItems(filteredItems)
      }
    }
  }, [filter])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const timeOut = 600

  const handleFilter = (filter) => ()=> {
    setFilter(filter)
    handleClose()
  };

  return (
    <div className={classes.container}>
      <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'center', alignItems: 'center' }}>
        <Typography className={classes.header}>Projects</Typography>
        <FontAwesomeIcon 
          icon={faFilter} 
          className={classes.icon} 
          aria-describedby={id}  
          onClick={handleClick}
        /> 
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <List dense className={classes.list} subheader={<ListSubheader>Techonology</ListSubheader>}>
            {filters.map(value => {
              return (
                <>
                  <Divider />
                  <ListItem key={value.name} button onClick={handleFilter(value.name)} selected={filter===value.name}>
                    <ListItemText primary={value.name} />
                    <ListItemSecondaryAction>
                    <Badge badgeContent={value.count} color="primary" />
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              );
            })}
          </List>
         </Popover>
      </div>
      <hr className={classes.hr} />
      <Grid container spacing={3} justify="center">
        {
          items.map((project, index) => (
            <Grow in={true} timeout={timeOut + index}>
              <Grid item><ProjectCard {...project} /></Grid>
            </Grow>
          ))
        }
      </Grid>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  container:{
    width: '90%',
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column'
  },
  list: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  header:{
    fontFamily: 'Pacifico',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    marginTop: '10px',
    color: '#323332',
  },
  hr:{
    width: 70, 
    height: 7, 
    marginTop: 15,
    marginLeft: '50%',
    transform: 'translateX(-80%)',
    background: theme.palette.primary.main, 
    marginBottom: 20,
    borderRadius: 20,
    border: 'none'
  },
  grid:{
    width: 'auto',
  },
  gridImage:{
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  cardItem:{
    fontFamily: 'Raleway',
    background: 'rgba(143, 223, 241, 0.33)',
    display: 'inline-block',
    padding: '5px 10px',
    fontSize: '0.7rem',
    borderRadius: '50px',
    fontWeight: 600,
    color: '#0087A5',
    minWidth: 60,
    textAlign: 'center',
    marginRight: 10,
    marginTop: 10
  },
  root: {
    width: 320,
    transition: '0.3s ease-in-out',
    boxShadow: '0px 5px 20px -5px rgba(0, 0, 0, 0.2)',
    '&:hover':{
      transition: '0.3s ease-in-out',
      transform: 'perspective(900px) translateZ(10px)',
      boxShadow: '0px 10px 50px -10px rgba(0, 0, 0, 0.2)'
    }
  },
  media: {
    height: 180,
  },
  icon:{
    marginTop: 20,
    paddingLeft: 10,
    fontSize: '2rem',
    color: '#323332',
    '&:hover':{
      cursor: 'pointer',
      transition: '0.2',
      color: '#000'
    }
  },
  icon1:{
    // marginTop: 20,
    marginLeft: 10,
    fontSize: '1.5rem',
    color: '#00AED4',
    '&:hover':{
      cursor: 'pointer',
      transition: '0.2s',
      color: '#0087A5'
    }
  },
  iconDisabled: {
    '&:hover': {
      cursor: 'not-allowed'
    }
  }
}));

export default Projects;