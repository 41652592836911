export default  {
  headerName: 'Brijesh',
  projects: [
    {
      projectName: 'EBLWG',
      projectDescription: '',
      projectImage: 'project2.png',
      tags: ['React', 'Material UI', 'Node', 'Express', 'MongoDB', 'JWT'],
      githubUrl: '',
      liveUrl: 'https://eblwg.herokuapp.com'
    },
    {
      projectName: 'Cornona virus Stats',
      projectDescription: 'Covid Status',
      projectImage: 'project4.jpg',
      tags: ['React', 'Material UI', 'Rapid API'],
      githubUrl: 'https://github.com/Brijesh59/corona-virus',
      liveUrl: 'https://brijesh-coronavirus-stats.netlify.app/'
    },
    {
      projectName: 'Tic-Tac-Toe Game',
      projectDescription: 'This is a tic-tac-toe game.',
      projectImage: 'project1.png',
      tags: ['React', 'Min-Max Algorithm'],
      githubUrl: 'https://github.com/Brijesh59/tic-tac-toe',
      liveUrl: 'https://brijesh-tictactoe.netlify.app/'
    },
    {
      projectName: 'Pair Game',
      projectDescription: 'This is a pair game.',
      projectImage: 'pair-game.png',
      tags: ['HTML', 'CSS', 'JS'],
      githubUrl: 'https://github.com/Brijesh59/pair-game2',
      liveUrl: 'https://brijesh59.github.io/pair-game2/'
    },
    {
      projectName: 'To do app',
      projectDescription: '',
      projectImage: 'todo-app.png',
      tags: ['React'],
      githubUrl: 'https://github.com/Brijesh59/react-Todo/tree/master',
      liveUrl: 'https://brijesh59.github.io/react-Todo/'
    },
    {
      projectName: 'Schoolwards - School Management System',
      projectDescription: '',
      projectImage: 'schoolwards2.png',
      tags: ['React Native', 'Firebase', 'RealmDB', 'Native Base'],
      githubUrl: '',
      liveUrl: ''
    },
    {
      projectName: 'Web Crawler for Flight Details',
      projectDescription: '',
      projectImage: 'webcrawler.png',
      tags: ['HTML', 'CSS', 'JS'],
      githubUrl: '',
      liveUrl: 'https://brijesh59.github.io/Web-Scrapper/index.html'
    }
  ]
}