import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

function AppInput({placeholder, value, name, onChange, multiline=false, rows=0}) {
  const classes = useStyles()
  return (
    <div>
      <FormControl className={classes.textField} variant="outlined">
        <OutlinedInput
          name={name}
          style={multiline? {padding: 10, borderRadius: 0} : {padding: 0, borderRadius: 0}}
          placeholder={placeholder}
          value={value}
          onChange={(e)=>onChange(e.target.name, e.target.value)}
          multiline={multiline}
          rows={rows}
          labelWidth={0}
        />
      </FormControl>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 0
  }
}));

export default AppInput;