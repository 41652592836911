import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Developer from './Developer';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';
import { NavContext } from '../../context/navContext';

function Home() {
  const classes = useStyles()
  return (
    <NavContext.Consumer>
      {
        (context)=>(
          <Grid 
            container 
            direction="row"
            justify="space-between"
            alignItems="center" 
            className={classes.container} >
            <Grid item sm={6} className={classes.gridLeft}>
              <Fade in={true} timeout={1500}>
                <Developer />
              </Fade>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.gridRight}>
              <Slide in={true} direction="up" timeout={1000} mountOnEnter unmountOnExit>
                <div>
                  <Typography className={classes.title1}>
                    Hi, 
                    I’m a Full Stack Software Engineer.
                  </Typography>
                  <Typography className={classes.title2}>
                    LOVE MY WORK. <br /> 
                    AIM TO IMPACT.
                  </Typography>
                  <Typography className={classes.title3}>
                    with my problem solving <br /> and coding skills.
                  </Typography>
                  <Button 
                    component={Link}
                    onClick={()=>context.changeNavValue(2)}
                    to='/projects'
                    variant="outlined" 
                    className={classes.btn}>
                      My Work
                  </Button>
                  <Button 
                    component={Link}
                    onClick={()=>context.changeNavValue(3)}
                    to='/contact'
                    variant="outlined" 
                    className={classes.btn2}>
                      Let's Talk
                  </Button>
                </div>  
              </Slide>
            </Grid>  
          </Grid>
        )
      }
    </NavContext.Consumer>
  );
}

const useStyles = makeStyles(theme => ({
  container:{
    width:'95%',
    marginTop: '5%', 
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      width:'80%',
      margin: '0px auto',
      marginTop: '5%', 
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0px auto',
    },
  },
  title1: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.7rem',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    }
  },
  title2: {
    fontWeight: 900,
    fontSize: '3.0rem',
    padding: 0,
    lineHeight: 1.1,
    letterSpacing: 2,
    wordSpacing: 5,
    color: 'rgba(0,0,0,0.9)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    }
  },
  title3: {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.5)',
    fontSize: '1.3rem',
    letterSpacing: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    }
  },
  btn:{
    fontFamily: 'Raleway', 
    fontWeight: 600, 
    marginTop: 10, 
    background: "#4A90E2", 
    color: 'white', 
    border: '2px solid #4A90E2', 
    borderRadius: 25,
    textTransform: 'none',
    '&:hover':{
      background: "#4177B3", 
      border: '2px solid #4177B3', 
      transition: 'all 300ms linear'
    }
  },
  btn2:{
    fontFamily: 'Raleway', 
    fontWeight: 600, 
    marginTop: 10, 
    background: 'transparent',
    color: '#171817',
    border: '2px solid #4A90E2', 
    borderRadius: 25,
    textTransform: 'none',
    marginLeft: 10,  
    '&:hover':{
      background: "#4A90E2", 
      color: "#fff",
      transition: 'all 300ms linear'
    }
  },
  gridLeft: {
    [theme.breakpoints.down('sm')]: {
      // display: 'none',
      marginBottom: 20
    }
  },
  gridRight: {
    [theme.breakpoints.up('sm')]: {
      float: 'right',
      position: 'absolute',
      right: 0,
    }
  }
}));

export default Home;