
import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#4A90E2',
    },
    secondary: {
      main: '#ffffff'
    }
  },
  typography:{
    fontFamily: 'Raleway',
  }
})