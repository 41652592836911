import React, { createContext, useState, useEffect } from 'react'
export const NavContext = createContext()

export default function NavContextProvider({children}){
  const [currentNav, setCurrentNav] = useState(0)
  useEffect(()=>{
    const location = window.location.pathname
    switch(location){
      case '/': setCurrentNav(0); break;
      // case '/about': setCurrentNav(1); break;
      case '/skills': setCurrentNav(1); break;
      case '/projects': setCurrentNav(2); break;
      case '/contact': setCurrentNav(3); break;
      default: setCurrentNav(0)
    }
  }, [currentNav])
  const changeNavValue = (e, v) => {
    setCurrentNav(v)
  }
  return (
    <NavContext.Provider value={{currentNav, changeNavValue}}>
      {children}
    </NavContext.Provider>
  )
}