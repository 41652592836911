import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Fade } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import AppInput from '../common/AppInput'
import AppButton from '../common/AppButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMailBulk, faMapMarked } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithubSquare, faAngellist } from '@fortawesome/free-brands-svg-icons'

import axios from 'axios'

function Icon({icon, link}){
  const classes = useStyles()
  return (
    <a href={link} target='_blank' rel="noopener noreferrer">
      <FontAwesomeIcon icon={icon} className={classes.icon}  />
    </a>
  )
}

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


function Contact() {
  const classes = useStyles()
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    backgroundColor: '#000'
  })
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (field, value) => {
    if(field === 'name'){
      setName(value)
    }
    else if(field === 'email'){
      setEmail(value)
    }
    else if(field === 'message'){
      setMessage(value)
    }
  }

  const validateFields = () => {
    const result = {
      error: null,
      success: null
    }
    if(name.trim() === ''){
      result.error = 'Please provide your name.'
      return result
    }
    if(name.length < 3){
      result.error = 'Please provide a name with more than 3 characters'
      return result
    }
    if(email.trim() === ''){
      result.error = 'Please provide your email.'
      return result
    }
    if (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email) !== true){
      result.error = 'Please provide a valid email id.'
      return result
    }
    if(message.trim() === ''){
      result.error = 'Please provide your message.'
      return result
    }
    if(message.length < 5){
      result.error = 'Please add some more details in the message.'
      return result
    }
    return result
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
   
    const {error} = validateFields()
    if(!error){
      // send to netlify form
      const formData = { 
        "form-name": "contact", 
        "name": name, 
        "email": email, 
        "message": message 
      }
      fetch("https://kumarbrijesh.com/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode(formData)
      })
      .then((response) => console.log(response))
      .catch(error => console.log('Error in netlify form:', error));

      // twilio whatsapp message
      axios.post('https://whatsapp-twilio-message.herokuapp.com/', {
        name, email, message
      })
      .then(response =>{
        setIsLoading(false)
        if(response.data === 'queued'){
          setAlert({
            open: true,
            backgroundColor: '#4BB543',
            message: 'Message Recieved. I will get back to you shortly.'
          })
          setName('')
          setEmail('')
          setMessage('')
        }
      })
      .catch(error => {
        setIsLoading(false)
        setAlert({
          open: true,
          backgroundColor: '#FF3232',
          message: error.message + '. Please can Connect with email or linkedin.'
        })
      })
    }
    else{
      setIsLoading(false)
      setAlert({
        open: true,
        backgroundColor: '#FF3232',
        message: error
      })
    }  
  }

  return (
    <Fade in={true} timeout={1000}>
      <Grid 
        container 
        direction="row"
        justify="space-between"
        alignItems="center" 
        className={classes.container} >
        <Grid item  xs className={classes.gridLeft}>
            <Typography className={classes.title2}>
              Let's do <span role="img" aria-label="hi"> 👋🏻</span>  <br />
              something <br />
              amazing.
            </Typography>
            <br />
            <Typography className={classes.title4}>
              <FontAwesomeIcon icon={faMapMarked} className={classes.icon1}/> UP, India
            </Typography>
            <Typography className={classes.title4}>
              <FontAwesomeIcon icon={faMailBulk} className={classes.icon1}/> kumarbrijesh9021@gmail.com
            </Typography>
            <Icon icon={faGithubSquare} link='https://github.com/Brijesh59' />
            <Icon icon={faLinkedin} link='https://www.linkedin.com/in/brijesh-kumar-kushwaha-202a84149' />
            <Icon icon={faAngellist} link='https://angel.co/u/brijesh-kumar-kushwaha' />
        </Grid>
        <Grid item xs className={classes.gridRight}>
          <Typography className={classes.mobileMenu1}>
            Let's do something amazing 
            <span role="img" aria-label="hi"> 👋🏻</span>
          </Typography>
          <form onSubmit={handleSubmit}>
            <AppInput name="name" placeholder="Name" value={name} onChange={handleChange} />
            <AppInput name="email" placeholder="Email" value={email} onChange={handleChange}/>
            <AppInput name="message" placeholder="Message" multiline rows="4" value={message} onChange={handleChange}/>
            <AppButton type="submit" onClick={handleSubmit} isLoading={isLoading}>
              {isLoading ? 'Sending...' : 'Send Message'}
            </AppButton>  
          </form> 
          <Snackbar 
            open={alert.open} 
            autoHideDuration={4000} 
            onClose={()=>setAlert({...alert, open:false})}
            message={alert.message}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            ContentProps={{style:{backgroundColor: alert.backgroundColor, fontWeight:500 }}}
          />
          <div className={classes.mobileMenu2}>
            <Typography className={classes.title4}>
              <FontAwesomeIcon icon={faMapMarked} className={classes.icon1}/> UP, India
            </Typography>
            <Typography className={classes.title4}>
              <FontAwesomeIcon icon={faMailBulk} className={classes.icon1}/> kumarbrijesh9021@gmail.com
            </Typography>
            <Icon icon={faGithubSquare} link='https://github.com/Brijesh59' />
            <Icon icon={faLinkedin} link='https://www.linkedin.com/in/brijesh-kumar-kushwaha-202a84149' />
            <Icon icon={faAngellist} link='https://angel.co/u/brijesh-kumar-kushwaha' />
          </div>
        </Grid>
      </Grid>  
    </Fade>
  );
}

const useStyles = makeStyles(theme => ({
  container:{
    width:'95%',
    marginTop: '5%', 
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('xs')]: {
      width:'80%',
      margin: '0px auto',
      marginTop: '5%', 
    },
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      margin: '0px auto',
    },
  },
  gridLeft: {
    // background: 'red',
    // display: 'flex',
    // justifyContent: 'flex-end',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  gridRight: {
    // background:  'red',
    // [theme.breakpoints.up('xs')]: {
    //   width: '70%'
    // }
  },
  title2: {
    fontWeight: 900,
    fontSize: '4rem',
    lineHeight: 1.1,
    letterSpacing: 1.6,
    wordSpacing: 5,
    color: '#323332',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    }
  },
  
  btn:{
    fontFamily: 'Raleway', 
    fontWeight: 600, 
    marginTop: 10, 
    border: 'none', 
    borderRadius: 0, 
    padding: '10px 20px',  
    background: "#323332", 
    color: 'white',
    textTransform: 'none',
    '&:hover':{
      background: '#323332',
      transition: '0.2s'
    },
    margin: {
      margin: theme.spacing(1),
    },
    textField: {
      width: '25ch',
      borderRadius: 0
    },
  },
  mobileMenu1:{
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      fontWeight: 700,
      fontSize: '2rem',
      margin: '10px 0px',
      padding: 0,
      lineHeight: 1.2,
      wordSpacing: 5,
      color: '#323332',
    }
  },
  mobileMenu2:{
    display: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'block',
      marginTop: 20,
    }
  },
  icon1:{
    marginRight: 10,
    color: '#323332',
  },
  icon:{
    margin: '15px 20px 10px 0px',
    fontSize: '2.3rem',
    color: '#323332',
    '&:hover':{
      cursor: 'pointer',
      transition: '0.2',
      color: '#000'
    }
  }
}));

export default Contact;