import React from 'react';
import './App.css';
import Home from './components/Home';
import { ThemeProvider } from '@material-ui/core/styles';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import theme from './config/theme'
import Header from './components/Header.js';
import Skills from './components/Skills';
import Contact from './components/Contact/Contact';
import Projects from './components/Projects';
import NavContextProvider from './context/navContext'
import About from './components/About';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <NavContextProvider>
        <Router>
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about" component={About} />
            <Route exact path="/skills" component={Skills} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </Router>
      </NavContextProvider>
    </ThemeProvider>
  );
}

export default App;
