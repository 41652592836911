import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import { Link } from 'react-router-dom';
import { NavContext } from '../../context/navContext';

function Home(props) {
  const classes = useStyles()
  return (
    <NavContext.Consumer>
      {
        (context)=>(
          <Grid container justify="center" alignItems="center" 
            style={{width:'95%', overflow: 'hidden'}}>
            <Slide in={true} direction="up" timeout={600} mountOnEnter unmountOnExit>
              <Grid item xs={12} sm={6} className={classes.grid2}>
                <div style={{marginLeft: '10%'}}>
                  <Typography className={classes.title1}>
                    Hi, I’m a Full Stack Software Engineer.
                  </Typography>
                  <Typography className={classes.title2}>
                    LOVE MY WORK. <br /> 
                    AIM TO IMPACT.
                  </Typography>
                  <Typography className={classes.title3}>
                    with my problem solving <br /> and coding skills.
                  </Typography>
                  <Button 
                    component={Link}
                    onClick={()=>context.changeNavValue(4)}
                    to='/contact'
                    variant="outlined" 
                    style={{fontFamily: 'Raleway', fontWeight: 600, marginTop: 10, background: "#4A90E2", color: 'white', border: 0, borderRadius: 25,textTransform: 'none' }}>Let's Talk</Button>
                </div>  
              </Grid>
            </Slide>
            <Fade in={true} timeout={1500}>
              <Grid item sm={6} className={classes.grid1}>
                <div style={{marginLeft: '5%'}}>
                  <img 
                    src={require('../../assets/profileImage.png')} 
                    alt="developer" 
                    width="100%" 
                    height="100%" 
                  />
                </div>  
              </Grid>
            </Fade>
          </Grid>
        )
      }
    </NavContext.Consumer>
  );
}

const useStyles = makeStyles(theme => ({
  title1: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '1.3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    }
  },
  title2: {
    fontWeight: 900,
    fontSize: '2.5rem',
    margin: '10px 0px',
    padding: 0,
    lineHeight: 1.1,
    letterSpacing: 2,
    wordSpacing: 5,
    color: 'rgba(0,0,0,0.9)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    }
  },
  title3: {
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.5)',
    fontSize: '1.3rem',
    letterSpacing: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    }
  },
  grid1: {
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  }
}));

export default Home;