import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBolt } from '@fortawesome/free-solid-svg-icons'

function CardItem({children}){
  const classes = useStyles()
  return (
    <span>
      <Typography className={classes.cardItem}>
        {children}
      </Typography>
    </span>
  )
}

function CardHeader({children}){
  return (
    <span>
      <Typography style={{
        fontSize: '1.5rem',
        fontWeight: 600
      }}>
        {children}
      </Typography>
    </span>
  )
}

function Skills(props) {
  const classes = useStyles()
  return (
    <Fade in={true} timeout={1000}>
    <div className={classes.container}>
      <Typography className={classes.header}>
        Professional Skills  
        <FontAwesomeIcon icon={faBolt} className={classes.icon}/> 
      </Typography> <br />
      <Grid container spacing={3} alignItems="center" className={classes.grid}>        
        <Grid item xs={12}>
          <hr style={{
            background:' #AFB3B3', 
            width:'100%',
            height:1,
            border: 'none'
          }}/>
        </Grid>
        <Grid item className={classes.gridImage}> 
          <img src={require('../../assets/skills1.svg')} width='100' alt="" />
        </Grid>
        <Grid item>
          <CardHeader>Front-End</CardHeader>
          <CardItem>HTML5</CardItem>
          <CardItem>CSS3</CardItem>
          <CardItem>JS</CardItem>
          <CardItem>ES6</CardItem> <br />
          <CardItem>React</CardItem>
          <CardItem>Redux</CardItem>
          <CardItem>Material UI</CardItem>
        </Grid>
      </Grid>
      
      <Grid container spacing={3} alignItems="center" className={classes.grid} style={{marginTop:20}}>
        <Grid item xs={12}>
          <hr style={{
            background:' #AFB3B3', 
            width:'100%',
            height:1,
            border: 'none'
          }}/>
        </Grid>
        <Grid item className={classes.gridImage}>
          <img src={require('../../assets/skills2.svg')} width='100' height='100' alt="" />
        </Grid>
        <Grid item>
          <CardHeader>Back-End</CardHeader>
          <CardItem>Node</CardItem>
          <CardItem>Express</CardItem>
          <CardItem>Rest API <span role="img" aria-label="heart">❤️</span></CardItem> 
          <CardItem>MongoDB</CardItem> <br />
          <CardItem>Mongoose</CardItem>
          <CardItem>MySql</CardItem>
        </Grid>
      </Grid>

      <Grid container spacing={3} alignItems="center" className={classes.grid} style={{marginTop:20, marginBottom:20}}>
        <Grid item xs={12}>
            <hr style={{
              background:' #AFB3B3', 
              width:'100%',
              height:1,
              border: 'none'
            }}/>
          </Grid>
        <Grid item className={classes.gridImage}>
          <img src={require('../../assets/skills3.svg')} width='100' alt=""/>
        </Grid>
        <Grid item>
          <CardHeader>DevOps & More ...</CardHeader>
          <CardItem>CI/CD</CardItem>
          <CardItem>Unit & Integration testing</CardItem>
          <CardItem>Git</CardItem> <br />
          <CardItem>Github</CardItem> 
          <CardItem>Heroku</CardItem>
          <CardItem>Firebase</CardItem>
          <CardItem>AWS</CardItem> <br />
          <CardItem>React Native <span role="img" aria-label="star">🌟</span></CardItem> 
          <CardItem>DS & Algo</CardItem>
          <CardItem>Problem Solving</CardItem>
        </Grid>
      </Grid>
    </div>
    </Fade>
  );
}

const useStyles = makeStyles(theme => ({
  container:{
    width: '90%',
    margin: '0px auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  header:{
    fontFamily: 'Pacifico',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '40px',
    marginTop: '10px',
    color: '#323332'
  },
  grid:{
    width: 'auto',
  },
  gridImage:{
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  cardItem:{
    fontFamily: 'Montserrat',
    background: '#E7ECEC',
    display: 'inline-block',
    padding: '2px 10px',
    border: '2px solid #DEDFE4',
    borderRadius: '3px',
    color: '#323334',
    minWidth: 90,
    textAlign: 'center',
    marginRight: 10,
    marginTop: 10,
    '&:hover':{
      background: '#DEDFE4',
      transition: '0.2s'
    }
  },
  icon: {
    marginLeft: 15,
    color: '#F4C43E'
  }
}));

export default Skills;